<template>
    <div>
        <NavBar v-if="!$route.path.includes('rule') && $route.path !== '/home' && $route.path !== '/' && $route.path !== '/auth/login' "/>
        <router-view :class="$route.path.includes('login') ? '' : 'middle-container'"></router-view>
        <Footer v-if="!$route.path.includes('rule') && !$route.path.includes('auth/login')"/>
        <!-- <div class="social-contacts" id="social-contacts">
            <div
                style="position: relative"
                class="cart-container"
                v-if="
                    $route.path.includes('maung-fb') ||
                    $route.path.includes('body-fb')
                "
                @click="openModalVoucher()"
            >
                <div>
                    <i class="ni ni-cart mb-2"></i>
                </div>
                <span
                    class="
                        badge badge-md badge-circle badge-floating badge-danger
                        border-white
                        cart-number
                    "
                    v-if="$route.path.includes('2d')"
                    >{{ this.$store.state.twod_slip_data.length }}</span
                >
                <span
                    class="
                        badge badge-md badge-circle badge-floating badge-danger
                        border-white
                        cart-number
                    "
                    v-if="$route.path.includes('fb')"
                    >{{ this.$store.state.fb_order.fb_matches.length }}</span
                >
                <span
                    class="
                        badge badge-md badge-circle badge-floating badge-danger
                        border-white
                        cart-number
                    "
                    v-else-if="$route.path.includes('3d')"
                    >{{ this.$store.state.threed_slip_data.length }}</span
                >
            </div>
        </div> -->
        
        <audio controls ref="audioPlayer" v-show="false">
          <!-- Replace 'your-audio-file.mp3' with the path to your audio file -->
          <source src="/build/assets/music/fbmusic.mp3" type="audio/mpeg">
          Your browser does not support the audio element
        </audio>
        <!-- <div>
          <FloatIcon />
        </div> -->
        

        <!-- <van-overlay :show="isLuckyWheel">
          <div class="wrapper col-8" @click.stop>
            <LuckyWheel />
          </div>
      </van-overlay> -->
    </div>
</template>
<script>

import { Dialog } from 'vant';
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import LuckyWheel from '../frontend/components/LuckyWheel.vue';

export default {
  components: {
    LuckyWheel,
  },
  data() {
    return {
      isLuckyWheel: false,
      isHideForToday: false,
      showPopup: false,
    };
  },
  async mounted() {
    this.checkPopupStatus();
    this.fetchWebsiteData();
  },
  computed: {
    ...mapGetters(['getFBOrder']),
    ...mapState(['music']),
  },

  watch: {
    music() {
      //do something on data change
      const audio = this.$refs.audioPlayer;
      audio.volume = 0.7;
      if(this.music === true){
        audio.play();
      } else {
        audio.pause();
      }
    }
  },

  methods: {  
    testing() {
      this.isLuckyWheel = true;
      console.log('testing');
    },
    closePopup() {
      this.showPopup = false;
      this.$store.commit('setMusic', true);
      const today = new Date().toISOString().split('T')[0];
      if(this.isHideForToday) localStorage.setItem('popupDismissedDate', today);
    },
    checkPopupStatus() {
      const today = new Date().toISOString().split('T')[0];
      const lastDismissedDate = localStorage.getItem('popupDismissedDate');
      if (lastDismissedDate !== today) {
        this.showPopup = true;
      }
    },

    ...mapMutations(['setWholeFBMatches']),
    ...mapActions(['fetchUser']),
    closeModalOutside(event) {
      if (event.target.classList.contains('modal')) {
        this.$router.go();
      }
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        console.log('this.$store.state.androidVersion', this.$store.state.androidVersion);
        if(res.data.data[0].androidVersion !== this.$store.state.androidVersion) {
          $('#version').modal('show');
        }
      } catch (error) {
        console.log(error);
      }
    },
    openModalVoucher() {
      if (this.$store.state.fb_order.fb_matches.length === 0) {
        const dataError = this.$store.state.language === 'en'
          ? 'Please select some teams!'
          : 'အသင်းတစ်ချို့ ရွေးပေးပါ။';
        this.dialog(dataError);
      } else {
        const matches = this.getFBOrder.fb_matches;
        if (!this.$store.state.betAmount) {
          $('#amount').modal('show');
        } else {
          for (let i = 0; i < matches.length; i += 1) {
            matches[i].bet_amount = this.$store.state.betAmount;
          }
          this.setWholeFBMatches(matches);
          $('#mobile-voucher').modal('show');
        }
      }
    },

    dialog(text) {
      Dialog.alert({
        message: text,
        confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Go Back'
                      : 'နောက်သို့ပြန်သွားမည်။',
      }).then(() => {
        // on close
      });
    },
  },
};
</script>
<style scoped>
.floating {
    position: fixed;
    width: 90px;
    z-index: 1;
    animation: floating 20s infinite;
    bottom: 130px;
    right: 0%;
}
#closeButton {
    border: none;
    background: none;
    position: absolute;
    left: -15px;
    font-size: 20px;
    font-weight: bold;
    color: #b7b7b7;
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-20px);
    }

    100% {
        transform: translatey(0px);
    }
}


.cart-number {
    top: -2px !important;
    left: -2px !important;
}
.cart-number {
    position: absolute;
    top: -3px;
    left: -3px;
    text-align: center;
}
.middle-container {
    min-height: 75vh;
}
@media (max-width: 768px) {
    .cart-container {
        font-size: 30px !important;
    }
    .social-contacts div {
        width: 60px !important;
        height: 60px !important;
        margin-top: 15px;
    }
    #social-contacts .badge-circle.badge-md {
        width: 1.5rem !important;
        height: 1.5rem !important;
    }
    .cart-container {
        visibility: visible;
        font-size: 16px;
    }
    .middle-container {
        min-height: 100vh;
        margin: 0px !important;
        /* padding-top: 0.1rem !important; */
        /* margin-bottom: 50px !important; */
    }
}
</style>
