<template>
	<div class="pt-3 pb-5" style="height: 100vh; overflow-y: scroll;">
		<div :style="itemsCart.length > 0 ? 'margin-bottom: 7rem !important' : ''" style="margin-bottom: 8rem;">
			<div class="container d-flex justify-content-center mb-3">
				<div
					class="mr-2 pt-1"
					style="border-radius: 10px"
					v-if="liveMatches.length > 0 || pendingMatches.length > 0"
				>
					<div class="tabs">
						<button
							class="tab-button text-uppercase"
							:class="tab === 'Live' ? 'active' : ''"
							@click="openTab('Live')"
						>
							Live
						</button>
						<button
							class="tab-button text-uppercase"
							:class="tab === 'Pending' ? 'active' : ''"
							@click="openTab('Pending')"
						>
							Coming soon
						</button>
					</div>
					<!-- <b class="text-white px-3">Today Matches</b> -->
				</div>
				<div class="text-center align-items-center mt-5 pt-5" v-else>
					<Loading
						color="#02133E"
						size="24"
						class="px-4 py-0 m-auto pb-2 pt-5"
					/>
					<span class="text-danger font-weight-bold">
						{{$t('please_wait')}}
					<br>
						{{
							$store.state.language === "en"
								? "Football matches are fetching! "
								: "ခေတ္တစောင့်ဆိုင်းပေးပါ။"
						}}
					</span>
				</div>

			</div>
			<div class="container">
				<div
					class="card work"
					v-for="(item, itemKey) in tab === 'Live'
						? liveMatches
						: pendingMatches"
					:key="itemKey"
					@click="tab === 'Live' ? redirectToLive('video-Links', item) : ''"
				>
					<div class="d-flex flex-column p-3">
						<div class="d-flex mb-2 justify-content-center text-dark">
							<b style="text-align: center">{{ item.league_name }}</b>
						</div>
						<div class="d-flex justify-content-around">
							<div
								class="d-flex flex-column align-items-center"
								style="width: 130px"
							>
								<img :src="item.home_team_logo" />
								<h3 class="pt-2" style="font-weight: bold">
									{{ item.home_team_name }}
								</h3>
							</div>
							<div class="d-flex flex-column">
								<div class="mt-4 mb-1 mx-auto">
									<p class="text-white pt-1 my-auto" id="pTag">Vs</p>
								</div>
								<div
									class="mx-auto mt-2"
									v-if="item.match_status.toLowerCase() === 'live'"
								>
									<p class="badge badge-danger pt-2 my-auto live">LIVE</p>
								</div>
								<div
									class="mx-auto mt-2"
									v-if="item.match_status.toLowerCase() !== 'live'"
								>
									<p class="badge badge-info pt-2 my-auto coming">
										{{ item.match_time }}
									</p>
								</div>
							</div>
							<div
								class="d-flex flex-column align-items-center"
								style="width: 130px"
							>
								<img :src="item.away_team_logo" />
								<h3 class="pt-2" style="font-weight: bold">
									{{ item.away_team_name }}
								</h3>
							</div>
						</div>
					</div>
					<div class="layer">
						<i
							class="fa-solid fa-circle-play text-white"
							style="font-size: 50px !important"
						></i>
						<h5 class="pt-1 text-white">
							{{ $store.state.language === "en" ? "Play" : "Play" }}
						</h5>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Loading } from 'vant';
import { mapActions } from 'vuex';
	export default {
		components: { Loading },
		name: "Live",
		data: () => ({
			itemsCart: [],
			tab: "Live",
			liveMatches: [],
			pendingMatches: [],
		}),
		methods: {
			...mapActions(['fetchUser']),
			openTab(tabName) {
				this.tab = tabName;
			},
			redirectToLive(path, item, isLive) {
				localStorage.setItem('servers', JSON.stringify(item.servers));
				this.$router.push({ path, query: { item } });
			},
			async translateText(text, targetLanguage) {
				try {
					const translate = require("google-translate-api");

					const { text: translatedText } = await translate(text, {
						to: targetLanguage,
					});
					console.log(`Original: ${text}`);
					console.log(`Translation: ${translatedText}`);
				} catch (error) {
					console.error("Error translating text:", error);
				}
			},
			async redirectRoute(path, query) {
				try {
					console.log("query", query);
					// if (path === 'tip') {
					//   const text = this.$store.state.language === 'en'
					//     ? 'This feature is not support yet.'
					//     : 'ယခုအချိန်တွင် အသုံးပြုလို့မရနိုင်သေးပါ။';
					//   Dialog.alert({
					//     message: text,
					//     confirmButtonText:
					//                     this.$store.state.language === 'en'
					//                       ? 'Okay'
					//                       : 'Okay',
					//   }).then(() => {
					//     // on close
					//   });
					// } else {
					this.$router.push({ path, query });
					// }
				} catch (error) {
					console.log(error);
				}
			},
			async fetchMatches() {
				try {
					// const res = await axios.get("https://back.mandalarthu.com/api/football-live-matches-api");
					// // add matches to liveMatches when match_status is live
					// this.liveMatches = res?.data?.data?.filter(
					// 	(match) => match.match_status?.toLowerCase() === "live" && match.servers.length > 0
					// );
					// this.pendingMatches = res?.data?.data?.filter(
					// 	(match) => match.match_status.toLowerCase() !== "live"
					// );
					// this.itemsCart = res.data.data;
				const apiUrl = 'https://back.mandalarthu.com/api/football-live-matches-api';

				// Define your API key
				const apiKey = 'kfblaaekjI9MBqUOnUfifsdawAq75WbFg3aL1Xoh85uNqjtfSP6Y13YxHrXvw';

						// Make a GET request using the fetch function with headers
						fetch(apiUrl, {
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
								'x-api-key': apiKey // Add the x-api-key header
							},
						})
				.then(response => {
				// Check if the request was successful (status code 2xx)
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				// Parse the JSON response
				return response.json();
				})
				.then(data => {
					// add matches to liveMatches when match_status is live
					this.liveMatches = data?.data?.filter(
						(match) => match.match_status?.toLowerCase() === "live" && match.servers.length > 0
					);
					this.pendingMatches = data?.data?.filter(
						(match) => match.match_status.toLowerCase() !== "live"
					);
				})
				.catch(error => {
					// Handle errors
					console.error('Error fetching data:', error);
				});
				} catch (error) {
					console.log(error);
				}
			},
		},
		watch: {
			tab() {
				if (this.tab === "Live") {
				}
			},
		},
		async mounted() {
			const authUser = await this.fetchUser();
			if (!authUser) {
				this.$router.push('/auth/login');
			}
			this.fetchMatches();
		},
	};
</script>

<style scoped>
	.card {
		cursor: pointer;
		width: 100%;
		/* height: 170px; */
		border-radius: 10px !important;
	}

	img {
		width: 70px;
		height: 70px;
	}

	#pTag {
		width: 60px;
		height: 30px;
		background: #303030;
		font-weight: bolder;
		border-radius: 20px;
		text-align: center;
	}

	.layer {
		opacity: 0.9;
		width: 100%;
		height: 0;
		background: linear-gradient(#d7d2c9, #222222);
		border-radius: 10px;
		position: absolute;
		left: 0;
		bottom: 0;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 40px;
		text-align: center;
		font-size: 14px;
		transition: height 0.5s;
	}

	.work:hover img {
		transform: scale(1.1);
	}

	.work:hover .layer {
		height: 100%;
	}

	.live {
		animation-name: live-number-animation;
		animation-duration: 2s;
		animation-iteration-count: infinite;
	}

	@keyframes live-number-animation {
		from {
			background-color: red;
			color: white;
		}

		to {
			background-color: #fdd1da;
			color: red;
		}
	}

	.coming {
		animation-name: coming-number-animation;
		animation-duration: 2s;
		animation-iteration-count: infinite;
	}

	@keyframes coming-number-animation {
		from {
			background-color: #303030;
			color: white;
		}

		to {
			background-color: #9e9e9e;
			color: #fcfcfc;
		}
	}

	/* NEw */
	.tabs {
		display: flex;
		background-color: #ffffff;
		border-radius: 10px;
		overflow: hidden;
		width: 300px;
		padding: 4px;
		gap: 4px;
	}

	.tab-button {
		flex-grow: 1;
		background-color: #ffffff;
		border: none;
		border-radius: 10px;
		outline: none;
		cursor: pointer;
		padding: 10px 12px;
		transition: background-color 0.3s ease;
		font-size: 17px;
	}

	.tab-button:hover,
	.tab-button.active {
		background: linear-gradient(to right, #303030, #303030);
		color: white;
	}
</style>
